(function($, undefined){


    $(document).ready(function(){
        if ($('.js-videoBack').length > 0 && $('.js-videoBack').data('video') != '') {
            setTimeout(function(){
                var html =  '<video id="vid" height="100%" loop="loop" autoplay="autoplay" muted>';
                html     += '    <source src="' + $('.js-videoBack').data('video') + '" type=\'video/mp4; codecs="avc1.42E01E, mp4a.40.2"\'>';
                html     += '</video>';
                $('.js-videoBack').html(html);
                document.getElementById('vid').play();
            }, 1000);
        }
    });

    $(window).on('load resize', function(){
        if($(window).width() <= 991) {
            $('.h-menu ul li.menu-item-has-children').append('<span class="click js-click"></span>');
        }
    });



    $('.js-hBtn').click(function(){
        $('.js-mobMenu').fadeToggle()
    });

    $('.js-modalShow').click(function(e){
        e.preventDefault()
        $('.js-formTopic').val($(this).data('text'));
        $('.js-formTtl').text($(this).data('text'));
        $('.js-formBtnTxt').text($(this).data('text'));
        $('#modalWindow').arcticmodal();
    });
    $('.js-modalShow2').click(function(e){
        e.preventDefault();
        $('#modalWindow2').arcticmodal();
    });
    $('.js-modalShowDir').click(function(e){
        e.preventDefault();
        $('#modalWindowDir').arcticmodal();
    });

     $('.js-modalShowTeh').click(function(e){
        e.preventDefault();
        $('#modalWindowTeh').arcticmodal();
    });

   $('.menu-item-has-children').mouseover(function(){
        if($(window).width() > 991) {
            $(this).addClass('hovered');
        }
    });
    $('.menu-item-has-children').mouseleave(function(){
        if($(window).width() > 991) {
            $(this).removeClass('hovered');
        }
    });
    $('body').on('click', '.js-click', function(){
        if($(window).width() <= 991) {
            $(this).closest('.menu-item-has-children').toggleClass('hovered');
        }
    });



    $('.js-hotSlider').slick({
        prevArrow: '<svg width="13" height="23" viewBox="0 0 13 23" fill="none" xmlns="http://www.w3.org/2000/svg" class="slick-arrow arrow-prev"> <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7234 22.6905C13.1047 22.291 13.09 21.658 12.6905 21.2766L2.44828 11.5L12.6905 1.72336C13.09 1.34202 13.1047 0.709024 12.7234 0.309526C12.342 -0.089972 11.709 -0.104693 11.3095 0.276645L0.309524 10.7766C0.111843 10.9653 1.19456e-08 11.2267 0 11.5C-1.19456e-08 11.7733 0.111842 12.0347 0.309523 12.2234L11.3095 22.7234C11.709 23.1047 12.342 23.09 12.7234 22.6905Z" fill="#F90936"/> </svg>',
        nextArrow: '<svg width="13" height="23" viewBox="0 0 13 23" fill="none" xmlns="http://www.w3.org/2000/svg" class="slick-arrow arrow-next"> <path fill-rule="evenodd" clip-rule="evenodd" d="M0.276647 22.6905C-0.104692 22.291 -0.0899714 21.658 0.309527 21.2766L10.5517 11.5L0.309526 1.72336C-0.0899723 1.34202 -0.104693 0.709024 0.276646 0.309526C0.657985 -0.089972 1.29098 -0.104693 1.69048 0.276645L12.6905 10.7766C12.8882 10.9653 13 11.2267 13 11.5C13 11.7733 12.8882 12.0347 12.6905 12.2234L1.69048 22.7234C1.29098 23.1047 0.657986 23.09 0.276647 22.6905Z" fill="#F90936"/> </svg>',
        fade: true
    });

    $('.js-revsSlider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        focusOnSelect: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000
    });
    $('.js-revsSliderText').slick({
        prevArrow: '<svg width="13" height="23" viewBox="0 0 13 23" fill="none" xmlns="http://www.w3.org/2000/svg" class="slick-arrow arrow-prev"> <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7234 22.6905C13.1047 22.291 13.09 21.658 12.6905 21.2766L2.44828 11.5L12.6905 1.72336C13.09 1.34202 13.1047 0.709024 12.7234 0.309526C12.342 -0.089972 11.709 -0.104693 11.3095 0.276645L0.309524 10.7766C0.111843 10.9653 1.19456e-08 11.2267 0 11.5C-1.19456e-08 11.7733 0.111842 12.0347 0.309523 12.2234L11.3095 22.7234C11.709 23.1047 12.342 23.09 12.7234 22.6905Z" fill="#F90936"/> </svg>',
        nextArrow: '<svg width="13" height="23" viewBox="0 0 13 23" fill="none" xmlns="http://www.w3.org/2000/svg" class="slick-arrow arrow-next"> <path fill-rule="evenodd" clip-rule="evenodd" d="M0.276647 22.6905C-0.104692 22.291 -0.0899714 21.658 0.309527 21.2766L10.5517 11.5L0.309526 1.72336C-0.0899723 1.34202 -0.104693 0.709024 0.276646 0.309526C0.657985 -0.089972 1.29098 -0.104693 1.69048 0.276645L12.6905 10.7766C12.8882 10.9653 13 11.2267 13 11.5C13 11.7733 12.8882 12.0347 12.6905 12.2234L1.69048 22.7234C1.29098 23.1047 0.657986 23.09 0.276647 22.6905Z" fill="#F90936"/> </svg>',
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        fade: true
    });
    $('.js-datesSlider').slick({
        prevArrow: '<svg width="13" height="23" viewBox="0 0 13 23" fill="none" xmlns="http://www.w3.org/2000/svg" class="slick-arrow arrow-prev"> <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7234 22.6905C13.1047 22.291 13.09 21.658 12.6905 21.2766L2.44828 11.5L12.6905 1.72336C13.09 1.34202 13.1047 0.709024 12.7234 0.309526C12.342 -0.089972 11.709 -0.104693 11.3095 0.276645L0.309524 10.7766C0.111843 10.9653 1.19456e-08 11.2267 0 11.5C-1.19456e-08 11.7733 0.111842 12.0347 0.309523 12.2234L11.3095 22.7234C11.709 23.1047 12.342 23.09 12.7234 22.6905Z" fill="#F90936"/> </svg>',
        nextArrow: '<svg width="13" height="23" viewBox="0 0 13 23" fill="none" xmlns="http://www.w3.org/2000/svg" class="slick-arrow arrow-next"> <path fill-rule="evenodd" clip-rule="evenodd" d="M0.276647 22.6905C-0.104692 22.291 -0.0899714 21.658 0.309527 21.2766L10.5517 11.5L0.309526 1.72336C-0.0899723 1.34202 -0.104693 0.709024 0.276646 0.309526C0.657985 -0.089972 1.29098 -0.104693 1.69048 0.276645L12.6905 10.7766C12.8882 10.9653 13 11.2267 13 11.5C13 11.7733 12.8882 12.0347 12.6905 12.2234L1.69048 22.7234C1.29098 23.1047 0.657986 23.09 0.276647 22.6905Z" fill="#F90936"/> </svg>',
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        infinite: false
    });
    var sertSlider = $('.js-sertSlider').slick({
        prevArrow: '',
        nextArrow: '',
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        infinite: true,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 899,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
    $('.js-sertPrev').click(function(){
        sertSlider.slick('slickPrev');
    });
    $('.js-sertNext').click(function(){
        sertSlider.slick('slickNext');
    });

    $('.js-funcSlider').slick({
        prevArrow: '<svg width="13" height="23" viewBox="0 0 13 23" fill="none" xmlns="http://www.w3.org/2000/svg" class="slick-arrow arrow-prev"> <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7234 22.6905C13.1047 22.291 13.09 21.658 12.6905 21.2766L2.44828 11.5L12.6905 1.72336C13.09 1.34202 13.1047 0.709024 12.7234 0.309526C12.342 -0.089972 11.709 -0.104693 11.3095 0.276645L0.309524 10.7766C0.111843 10.9653 1.19456e-08 11.2267 0 11.5C-1.19456e-08 11.7733 0.111842 12.0347 0.309523 12.2234L11.3095 22.7234C11.709 23.1047 12.342 23.09 12.7234 22.6905Z" fill="#F90936"/> </svg>',
        nextArrow: '<svg width="13" height="23" viewBox="0 0 13 23" fill="none" xmlns="http://www.w3.org/2000/svg" class="slick-arrow arrow-next"> <path fill-rule="evenodd" clip-rule="evenodd" d="M0.276647 22.6905C-0.104692 22.291 -0.0899714 21.658 0.309527 21.2766L10.5517 11.5L0.309526 1.72336C-0.0899723 1.34202 -0.104693 0.709024 0.276646 0.309526C0.657985 -0.089972 1.29098 -0.104693 1.69048 0.276645L12.6905 10.7766C12.8882 10.9653 13 11.2267 13 11.5C13 11.7733 12.8882 12.0347 12.6905 12.2234L1.69048 22.7234C1.29098 23.1047 0.657986 23.09 0.276647 22.6905Z" fill="#F90936"/> </svg>',
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        focusOnSelect: true,
        infinite: true
    });

    $('.js-prodSlider').slick({
        prevArrow: '<svg width="13" height="23" viewBox="0 0 13 23" fill="none" xmlns="http://www.w3.org/2000/svg" class="slick-arrow arrow-prev"> <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7234 22.6905C13.1047 22.291 13.09 21.658 12.6905 21.2766L2.44828 11.5L12.6905 1.72336C13.09 1.34202 13.1047 0.709024 12.7234 0.309526C12.342 -0.089972 11.709 -0.104693 11.3095 0.276645L0.309524 10.7766C0.111843 10.9653 1.19456e-08 11.2267 0 11.5C-1.19456e-08 11.7733 0.111842 12.0347 0.309523 12.2234L11.3095 22.7234C11.709 23.1047 12.342 23.09 12.7234 22.6905Z" fill="#F90936"/> </svg>',
        nextArrow: '<svg width="13" height="23" viewBox="0 0 13 23" fill="none" xmlns="http://www.w3.org/2000/svg" class="slick-arrow arrow-next"> <path fill-rule="evenodd" clip-rule="evenodd" d="M0.276647 22.6905C-0.104692 22.291 -0.0899714 21.658 0.309527 21.2766L10.5517 11.5L0.309526 1.72336C-0.0899723 1.34202 -0.104693 0.709024 0.276646 0.309526C0.657985 -0.089972 1.29098 -0.104693 1.69048 0.276645L12.6905 10.7766C12.8882 10.9653 13 11.2267 13 11.5C13 11.7733 12.8882 12.0347 12.6905 12.2234L1.69048 22.7234C1.29098 23.1047 0.657986 23.09 0.276647 22.6905Z" fill="#F90936"/> </svg>',
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        focusOnSelect: false,
        infinite: true
    });

    $('.js-newsSlider').slick({
        prevArrow: '<svg width="13" height="23" viewBox="0 0 13 23" fill="none" xmlns="http://www.w3.org/2000/svg" class="slick-arrow arrow-prev"> <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7234 22.6905C13.1047 22.291 13.09 21.658 12.6905 21.2766L2.44828 11.5L12.6905 1.72336C13.09 1.34202 13.1047 0.709024 12.7234 0.309526C12.342 -0.089972 11.709 -0.104693 11.3095 0.276645L0.309524 10.7766C0.111843 10.9653 1.19456e-08 11.2267 0 11.5C-1.19456e-08 11.7733 0.111842 12.0347 0.309523 12.2234L11.3095 22.7234C11.709 23.1047 12.342 23.09 12.7234 22.6905Z" fill="#F90936"/> </svg>',
        nextArrow: '<svg width="13" height="23" viewBox="0 0 13 23" fill="none" xmlns="http://www.w3.org/2000/svg" class="slick-arrow arrow-next"> <path fill-rule="evenodd" clip-rule="evenodd" d="M0.276647 22.6905C-0.104692 22.291 -0.0899714 21.658 0.309527 21.2766L10.5517 11.5L0.309526 1.72336C-0.0899723 1.34202 -0.104693 0.709024 0.276646 0.309526C0.657985 -0.089972 1.29098 -0.104693 1.69048 0.276645L12.6905 10.7766C12.8882 10.9653 13 11.2267 13 11.5C13 11.7733 12.8882 12.0347 12.6905 12.2234L1.69048 22.7234C1.29098 23.1047 0.657986 23.09 0.276647 22.6905Z" fill="#F90936"/> </svg>',
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
        focusOnSelect: false,
        infinite: true,
        responsive: [
            {
                breakpoint: 899,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $(document).ready(function () {
        $('.page_decis_sing-func-bl').each(function(){
            var par = $(this).closest('.slick-track');
            $(this).height(par.height());
        });
    });

    $(window).resize(function () {
        $('.page_decis_sing-func-bl').css('height', 'auto');
        setTimeout(function(){
            $('.page_decis_sing-func-bl').each(function(){
                var par = $(this).closest('.slick-track');
                $(this).height(par.height());
            });
        }, 500)
    });

    /*wialon sidebar*/
    var topOpt = 0;
    if ($('body').hasClass('logged-in')) {
        topOpt = 30;
    }
    $(window).on('load resize', function () {
        if ($(window).width() > 991 && $('.js-stickySide').length > 0) {

            let endPoint = ($('.js-sideProd').data('endpoint') && $('.js-sideProd').data('endpoint') != '') ? $('.js-sideProd').data('endpoint') : 'footer';

            function offsetsToJson(object) {
                var json = {
                    top: object.offset().top,
                    bottom: object.offset().top + object.outerHeight(true),
                    left: object.offset().left,
                    right: object.offset().left + object.outerHeight(true)
                }
                return json;
            }

            function scrollWatcher(_window, _sidebar) {
                var sidebarContainer = _sidebar.parent();

                if (_window.scrollTop() < sidebarContainer.offset().top) {
                    $('.js-stickySide').css('top', 0);
                } else if (_window.scrollTop() > sidebarContainer.offset().top + sidebarContainer.outerHeight(true)) {
                    $('.js-stickySide').css('top', '100%');
                } else {
                    var jsonWindow = {
                        top: _window.scrollTop(),
                        bottom: _window.scrollTop() + _window.height()
                    };
                    var jsonSidebar = offsetsToJson(_sidebar);
                    var jsonFooter = offsetsToJson($(endPoint));
                    scrollSorter(jsonWindow, jsonSidebar, jsonFooter);
                }
            }

            function scrollSorter(windowLoc) {
                $('.js-stickySide').offset({top: windowLoc.top + topOpt});
            }

            $(document).ready(function () {
                if (!$('.js-stickySideWrap').length > 0) {
                    $('.js-stickySideMain').append('<div class="js-stickySideWrap"></div>');
                    $('.js-stickySide').appendTo('.js-stickySideWrap');
                }
                setTimeout(function () {
                    var height = ($(endPoint).offset().top - $('.js-stickySideMain').offset().top) - $('.js-stickySide').outerHeight(true);
                    if ($('.js-stickySide').outerHeight(true) < $('.js-stickySideMain').outerHeight(true)) {
                        $('.js-stickySideWrap').height(height);
                        scrollWatcher($(window), $('.js-stickySide'), $(endPoint));
                    }
                }, 1000);
            });
            $(window).resize(function () {
                var height = ($(endPoint).offset().top - $('.js-stickySideMain').offset().top) - $('.js-stickySide').outerHeight(true);
                if ($('.js-stickySide').outerHeight(true) < $('.js-stickySideMain').outerHeight(true)) {
                    $('.js-stickySideWrap').height(height);
                    scrollWatcher($(window), $('.js-stickySide'), $(endPoint));
                }
            });
            $(window).scroll(function () {
                var height = ($(endPoint).offset().top - $('.js-stickySideMain').offset().top) - $('.js-stickySide').outerHeight(true);
                if ($('.js-stickySide').outerHeight(true) < $('.js-stickySideMain').outerHeight(true)) {
                    $('.js-stickySideWrap').height(height);

                    scrollWatcher($(window), $('.js-stickySide'), $(endPoint))
                }
            });

        }
    });
    /*wialon sidebar*/

    $(document).ready(function() {
        $(".fancybox").fancybox();
    });

})(jQuery);
